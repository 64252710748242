import React, { PropsWithChildren, useEffect } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Loading from '../components/Loading';
import { NavRoutes } from '../data/NavRoutes';
import { Navbar } from './Navbar';
import { Sidebar } from './Sidebar';
import { Footer } from './Footer';
import TradingAccountContextProvider from '../contexts/TradingAccountContext';
import CqgProvider from '../contexts/CqgContext';
import LayoutsContextProvider from '../contexts/LayoutsContext';
import SymbolContextProvider from '../contexts/SymbolContext';
import OrderContextProvider from '../contexts/OrdersContext';
import TiltContextProvider from '../contexts/TiltContext';
import { Column, Page } from './LayoutComponents';
import { Box, styled } from '@mui/material';
import SettingsContextProvider from '@contexts/SettingsContext';
import MarketStatusContextProvider from '@contexts/MarketStatusContext';
import LinkedContextProvider from '@/contexts/LinkedContext';
import UserNotificationsContextProvider from '@/contexts/UserNotificationsContext';
import { NotificationContainer } from '@components/common/react-notifications';
import ModalContextProvider from '@contexts/ModalContext';
import { useDeviceContext } from '@/contexts/DeviceContext';
import AutoUpdate from '@/components/autoUpdate';
import RiskSettingsLockoutContextProvider from '@/contexts/RiskSettingsLockoutContext';

export const UserProviders: React.FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const { loggedIn } = useAuth();
  if (loggedIn) {
    return (
      <SettingsContextProvider>
        <CqgProvider>
          <TradingAccountContextProvider>
            <LayoutsContextProvider>
              <SymbolContextProvider>
                <LinkedContextProvider>
                  <OrderContextProvider>
                    <MarketStatusContextProvider>
                      <UserNotificationsContextProvider>
                        <NotificationContainer />
                        <ModalContextProvider>
                          <RiskSettingsLockoutContextProvider>
                            <TiltContextProvider>{children}</TiltContextProvider>
                          </RiskSettingsLockoutContextProvider>
                        </ModalContextProvider>
                      </UserNotificationsContextProvider>
                    </MarketStatusContextProvider>
                  </OrderContextProvider>
                </LinkedContextProvider>
              </SymbolContextProvider>
            </LayoutsContextProvider>
          </TradingAccountContextProvider>
        </CqgProvider>
      </SettingsContextProvider>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const UserLayout: React.FC = (): JSX.Element => {
  const { loggedIn, requiredAgreements } = useAuth();
  const { isMobile } = useDeviceContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedIn) {
      navigate(NavRoutes.Login);
    } else if (requiredAgreements.length > 0) {
      navigate(NavRoutes.Agreements);
    }
  }, [loggedIn, requiredAgreements]);

  if (!loggedIn || requiredAgreements.length > 0) {
    return <Loading></Loading>;
  }

  if (isMobile) {
    return <UserProviders>{<Outlet />}</UserProviders>;
  }

  return (
    <UserProviders>
      <Page>
        <Sidebar />
        <Column flex='1' margin='0.5em' marginBottom='0' marginTop='0' overflow='hidden'>
          <Navbar showLayoutSelector />
          <Box flex='1' overflow='hidden'>
            {<Outlet />}
          </Box>
          <Footer />
          {!isMobile && <AutoUpdate />}
        </Column>
      </Page>
    </UserProviders>
  );
};

export default UserLayout;

const SuccessMessage = styled('div')(({ theme }) => ({
  color: theme.palette.red,
  fontWeight: 'bold',
  fontSize: '1.2em',
  margin: '0.5em',
  marginBottom: '0',
  textAlign: 'center',
  padding: '0.5em',
  backgroundColor: theme.palette.lightBg,
  borderRadius: '0.25em',
  border: `1px solid ${theme.palette.success}`,
  transform: 'translate(-50%, -50%)',
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 999
}));
