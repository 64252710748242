import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Typography } from '@mui/material';

dayjs.extend(utc);

type CountdownTimerProps = {
  style?: any;
  targetDate: dayjs.Dayjs | null;
  onComplete?: () => void;
};

const CountdownTimer = ({ targetDate, onComplete, style }: CountdownTimerProps) => {
  const calculateTimeLeft = () => {
    if (!targetDate) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const now = dayjs.utc();
    const difference = targetDate.diff(now);

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      if (
        newTimeLeft.days === 0 &&
        newTimeLeft.hours === 0 &&
        newTimeLeft.minutes === 0 &&
        newTimeLeft.seconds === 0
      ) {
        clearInterval(timer);
        if (onComplete) {
          onComplete();
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate, onComplete]);

  const days = () => {
    if (timeLeft.days === 0) {
      return '';
    }

    return `${timeLeft.days}d `;
  }

  const hours = () => {
    if (timeLeft.hours === 0 && timeLeft.days === 0) {
      return '';
    }

    return `${timeLeft.hours}h `;
  }

  const minutes = () => {
    if (timeLeft.minutes === 0 && timeLeft.hours === 0 && timeLeft.days === 0) {
      return '';
    }

    return `${timeLeft.minutes}m `;
  }

  const seconds = () => {
    if (timeLeft.seconds === 0 && timeLeft.minutes === 0 && timeLeft.hours === 0 && timeLeft.days === 0) {
      return '';
    }

    return `${timeLeft.seconds}s`;
  }

  return (
    <Typography style={style} component="span" sx={{ fontWeight: 500, fontSize: '1.3em' }}>
      {days()}
      {hours()}
      {minutes()}
      {seconds()}
    </Typography>
  );
};

export default CountdownTimer;
