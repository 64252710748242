import { useApi } from '@contexts/ApiContext';
import { Box, Card, CardContent, Checkbox, FormControlLabel, FormGroup, FormLabel, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSettings } from '@contexts/SettingsContext';
import Button, { ButtonType } from '@components/topstep/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import HelpMessage from '@components/helpMessage';
import { useTradingAccount } from '@contexts/TradingAccountContext';
import { IUserSettingsModel, JsonDocument, PersonalDailyAction, PersonalDailyLimitsRequest, RootElement, TradeLimitsModel, TradingAccountType } from '@api/userApi';
import { networkSpeed, chartPlotSide, topNavTextSize } from 'src/data/enumTypeMaps';
import { logException } from '@/helpers/exceptionHelper';
import { toast } from 'react-toastify';
import { StyledButton } from '@/components/styledComponents';
import { useDeviceContext } from '@/contexts/DeviceContext';
import config from '@/config';
import CountdownTimer from './countdown';
import { useModal } from '@/contexts/ModalContext';
import PersonalLockoutModal from './personalLockoutModal';
import dayjs from 'dayjs';
import { useCqg } from '@/contexts/CqgContext';
import { useRiskSettingsLockout } from '@/contexts/RiskSettingsLockoutContext';
import TradeClock from '@/components/settings/tradeClock';
import PersonalLockout from '@/components/settings/personalLockout';

interface RiskSettingsProps {
  onSaved?: () => void;
  onCancelled?: () => void;
  showButtons?: boolean;
}

const RiskSettings: React.FC<RiskSettingsProps> = ({ onSaved, onCancelled, showButtons }) => {
  const settings = useSettings();
  const { tradingAccountApi, tradeLimitApi } = useApi();
  const { activeTradingAccount } = useTradingAccount();
  const { isMobile } = useDeviceContext();
  const [risk, setRisk] = useState<number | null>(0);
  const [toMake, setToMake] = useState<number | null>(0);
  const [autoApply, setAutoApply] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [personalDailyLossLimit, setPersonalDailyLossLimit] = useState<number | null>(0);
  const [personalDailyLossLimitAction, setPersonalDailyLossLimitAction] = useState<PersonalDailyAction>(PersonalDailyAction.None);
  const [personalDailyProfitTarget, setPersonalDailyProfitTarget] = useState<number | null>(0);
  const [personalDailyProfitTargetAction, setPersonalDailyProfitTargetAction] = useState<PersonalDailyAction>(PersonalDailyAction.None);
  const [maxTradesPerDay, setMaxTradesPerDay] = useState<number | null>(0);
  const [maxTradesPerWeek, setMaxTradesPerWeek] = useState<number | null>(0);
  const [currentDayTrades, setCurrentDayTrades] = useState<number>(0);
  const [currentWeekTrades, setCurrentWeekTrades] = useState<number>(0);
  const [lockoutExpiration, setLockoutExpiration] = useState<dayjs.Dayjs | null>(null);
  const { openModal, lockedOutUntil, isLockedOut, doSettingsLockout } = useRiskSettingsLockout();
  const isFollower = activeTradingAccount && activeTradingAccount.isFollower;

  const ignoreMaxTradesChanges = React.useRef(false);

  const { hideModal, showModal } = useModal();

  useEffect(() => {
    setCurrentDayTrades(activeTradingAccount.currentDayTrades);
    setCurrentWeekTrades(activeTradingAccount.currentWeekTrades);

    if (ignoreMaxTradesChanges.current) {
      return;
    }

    setMaxTradesPerDay(activeTradingAccount.dailyTradeLimit);
    setMaxTradesPerWeek(activeTradingAccount.weeklyTradeLimit);
  }, [activeTradingAccount.currentDayTrades, activeTradingAccount.currentWeekTrades, activeTradingAccount.dailyTradeLimit, activeTradingAccount.weeklyTradeLimit, activeTradingAccount]);

  const hasDll = useMemo(() => {
    return activeTradingAccount.type == TradingAccountType.Express || activeTradingAccount.type == TradingAccountType.Live;
  }, [activeTradingAccount.type]);

  const resetDailyLimits = useCallback(() => {
    setPersonalDailyLossLimit(activeTradingAccount.personalDailyLossLimit);
    setPersonalDailyLossLimitAction(activeTradingAccount.personalDailyLossLimitAction);
    setPersonalDailyProfitTarget(activeTradingAccount.personalDailyProfitTarget);
    setPersonalDailyProfitTargetAction(activeTradingAccount.personalDailyProfitTargetAction);
  }, [activeTradingAccount.personalDailyLossLimit, activeTradingAccount.personalDailyLossLimitAction, activeTradingAccount.personalDailyProfitTarget, activeTradingAccount.personalDailyProfitTargetAction]);

  useEffect(() => {
    // If locked out, return risk settings to original values
    if (isLockedOut) {
      resetDailyLimits();

      // trade limits
      setMaxTradesPerDay(activeTradingAccount.dailyTradeLimit);
      setMaxTradesPerWeek(activeTradingAccount.weeklyTradeLimit);
    }
  }, [isLockedOut]);

  useEffect(() => {
    setLockoutExpiration(activeTradingAccount?.lockoutExpiration);
  }, [activeTradingAccount?.lockoutExpiration, activeTradingAccount]);

  useEffect(() => {
    resetDailyLimits();
  }, [resetDailyLimits]);

  useEffect(() => {
    setRisk(settings.risk);
    setToMake(settings.toMake);
    setAutoApply(settings.autoApply);
  }, [settings]);

  const cancel = useCallback(() => {
    setRisk(settings.risk);
    setToMake(settings.toMake);
    setAutoApply(settings.autoApply);
    setMaxTradesPerDay(activeTradingAccount.dailyTradeLimit);
    setMaxTradesPerWeek(activeTradingAccount.weeklyTradeLimit);
    setPersonalDailyLossLimit(activeTradingAccount.personalDailyLossLimit);
    resetDailyLimits();
    onCancelled && onCancelled();
  }, [settings, resetDailyLimits, activeTradingAccount]);

  const onSave = useCallback(() => {
    if (maxTradesPerDay != null && maxTradesPerDay != activeTradingAccount.dailyTradeLimit && maxTradesPerDay <= currentDayTrades) {
      toast('Max trades per day must be greater than the current day trades.', { type: 'error' });
      return;
    }

    if (maxTradesPerWeek != null && maxTradesPerWeek != activeTradingAccount.weeklyTradeLimit && maxTradesPerWeek <= currentWeekTrades) {
      toast('Max trades per week must be greater than the current week trades.', { type: 'error' });
      return;
    }

    if (personalDailyLossLimitAction != PersonalDailyAction.None && personalDailyLossLimit === null) {
      toast('Please enter a Personal Daily Loss Limit when you the action is not disabled.', { type: 'error' });
      return;
    }

    if (personalDailyProfitTargetAction != PersonalDailyAction.None && personalDailyProfitTarget === null) {
      toast('Please enter a Personal Daily Profit Target when you the action is not disabled.', { type: 'error' });
      return;
    }

    setIsLoading(true);

    const promises = [];
    let hasError = false;

    ignoreMaxTradesChanges.current = true;

    if (isSettingsChanged) {
      const model: IUserSettingsModel = {
        risk,
        toMake,
        autoApply: autoApply,
        autoCenter: settings.autoCenter,
        soundNotifications: settings.soundNotifications,
        custom: new JsonDocument({ rootElement: new RootElement(settings.customSettings) })
      };
      promises.push(settings.save(model));
    }

    if (isPersonalLimitChanged) {
      const dailyLimits = new PersonalDailyLimitsRequest({
        tradingAccountId: activeTradingAccount.accountId,
        personalDailyLossLimit: personalDailyLossLimit,
        personalDailyLossLimitAction: personalDailyLossLimitAction,
        personalDailyProfitTarget: personalDailyProfitTarget,
        personalDailyProfitTargetAction: personalDailyProfitTargetAction
      });

      promises.push(
        tradingAccountApi.setPersonalDailyLimits(dailyLimits).catch((e) => {
          hasError = true;
          logException(e, 'Error saving personal daily limits');
          toast('Error saving personal daily limits', { type: 'error', hideProgressBar: true });
        })
      );
    }

    if (isTradeLimitChanged) {
      const tradeLimits = new TradeLimitsModel({
        tradingAccountId: activeTradingAccount.accountId,
        dailyTradeLimit: maxTradesPerDay,
        weeklyTradeLimit: maxTradesPerWeek
      });

      promises.push(
        tradeLimitApi.addTradeLimits(tradeLimits).catch((e) => {
          hasError = true;
          logException(e, 'Error saving trade limits');
          toast('Error saving trade limits', { type: 'error', hideProgressBar: true });
        })
      );
    }

    Promise.all(promises)
      .then(() => {
        ignoreMaxTradesChanges.current = false;
        if (hasError) {
          setIsLoading(false);
        } else {
          setMaxTradesPerDay(maxTradesPerDay);
          setMaxTradesPerWeek(maxTradesPerWeek);
          activeTradingAccount.dailyTradeLimit = maxTradesPerDay;
          activeTradingAccount.weeklyTradeLimit = maxTradesPerWeek;
          setIsLoading(false);
          onSaved && onSaved();
          toast('Settings saved', { type: 'success', hideProgressBar: true });
        }
      })
      .catch((e) => {
        ignoreMaxTradesChanges.current = false;
        setIsLoading(false);
        logException(e, 'Error saving settings');
        toast('Error saving settings', { type: 'error', hideProgressBar: true });
      });
  }, [
    risk,
    toMake,
    autoApply,
    settings.save,
    activeTradingAccount.accountId,
    personalDailyLossLimit,
    personalDailyLossLimit,
    personalDailyLossLimitAction,
    personalDailyProfitTarget,
    personalDailyProfitTargetAction,
    maxTradesPerDay,
    maxTradesPerWeek,
    activeTradingAccount
  ]);

  const updateToMake = useCallback((input: string) => {
    const value = parseInt(input);
    if (value <= 0 || isNaN(value)) {
      setToMake(null);
      return;
    }
    setToMake(Math.max(0, value));
  }, []);

  const updateDailyLoss = useCallback(
    (input: string) => {
      let value = parseInt(input);
      if (value <= 0 || isNaN(value)) {
        setPersonalDailyLossLimit(null);
        return;
      }

      let maxPDLL = activeTradingAccount.dailyLoss || Math.max(100000, Math.max(activeTradingAccount.startingBalance, activeTradingAccount.balance));

      if (activeTradingAccount.type == TradingAccountType.Sim || activeTradingAccount.type == TradingAccountType.None) value = Math.min(value, maxPDLL);

      setPersonalDailyLossLimit(Math.max(0, value));
    },
    [activeTradingAccount.dailyLoss]
  );

  const updatePersonalDailyProfitTarget = useCallback((input: string) => {
    const value = parseInt(input);
    if (value <= 0 || isNaN(value)) {
      setPersonalDailyProfitTarget(null);
      return;
    }
    setPersonalDailyProfitTarget(Math.max(0, value));
  }, []);

  const updateRisk = useCallback((input: string) => {
    const value = parseInt(input);
    if (value <= 0 || isNaN(value)) {
      setRisk(null);
      return;
    }
    setRisk(Math.max(0, value));
  }, []);

  const updateMaxTradesPerDay = useCallback((input: string) => {
    const value = parseInt(input);
    if (value <= 0 || isNaN(value)) {
      setMaxTradesPerDay(null);
      return;
    }
    setMaxTradesPerDay(Math.max(0, value));
  }, []);

  const updateMaxTradesPerWeek = useCallback((input: string) => {
    const value = parseInt(input);
    if (value <= 0 || isNaN(value)) {
      setMaxTradesPerWeek(null);
      return;
    }
    setMaxTradesPerWeek(Math.max(0, value));
  }, []);

  const renderTradesRemaining = (maxTrades, tradesCount, period) => {
    if (maxTrades === null || maxTrades <= 0) {
      return null;
    }

    return (
      <Card variant='outlined'>
        <CardContent sx={{ padding: '1.2em', paddingBottom: '1em !important' }}>
          {maxTrades > tradesCount ? (
            <>
              <Typography sx={{ fontSize: '1.3em' }}>Trades Remaining for {period}.</Typography>
              <Typography component='span' sx={{ fontWeight: 500, fontSize: '1.3em' }}>
                {`${maxTrades - tradesCount} Remaining`}
              </Typography>
            </>
          ) : (
            <Typography sx={{ fontSize: '1.3em' }}>You have reached your Max Trades {period === 'Today' ? 'Today' : 'this week.'}</Typography>
          )}
        </CardContent>
      </Card>
    );
  };

  const isChangedAccountSettings = useMemo(() => {
    return (
      personalDailyLossLimit !== activeTradingAccount.personalDailyLossLimit ||
      personalDailyLossLimit != activeTradingAccount.personalDailyLossLimit ||
      personalDailyLossLimitAction != activeTradingAccount.personalDailyLossLimitAction ||
      personalDailyProfitTarget != activeTradingAccount.personalDailyProfitTarget ||
      personalDailyProfitTargetAction != activeTradingAccount.personalDailyProfitTargetAction ||
      maxTradesPerDay != activeTradingAccount.dailyTradeLimit ||
      maxTradesPerWeek != activeTradingAccount.weeklyTradeLimit
    );
  }, [
    personalDailyLossLimit,
    personalDailyLossLimitAction,
    personalDailyProfitTarget,
    personalDailyProfitTargetAction,
    activeTradingAccount.personalDailyLossLimit,
    activeTradingAccount.personalDailyLossLimitAction,
    activeTradingAccount.personalDailyProfitTarget,
    activeTradingAccount.personalDailyProfitTargetAction,
    activeTradingAccount.dailyTradeLimit,
    activeTradingAccount.weeklyTradeLimit,
    maxTradesPerDay,
    maxTradesPerWeek,
    isLoading
  ]);

  const isChanged = useMemo(() => {
    return (
      risk !== settings.risk ||
      toMake !== settings.toMake ||
      autoApply !== settings.autoApply ||
      personalDailyLossLimit !== activeTradingAccount.personalDailyLossLimit ||
      personalDailyLossLimit != activeTradingAccount.personalDailyLossLimit ||
      personalDailyLossLimitAction != activeTradingAccount.personalDailyLossLimitAction ||
      personalDailyProfitTarget != activeTradingAccount.personalDailyProfitTarget ||
      personalDailyProfitTargetAction != activeTradingAccount.personalDailyProfitTargetAction ||
      maxTradesPerDay != activeTradingAccount.dailyTradeLimit ||
      maxTradesPerWeek != activeTradingAccount.weeklyTradeLimit
    );
  }, [
    risk,
    toMake,
    autoApply,
    settings,
    settings.customSettings,
    personalDailyLossLimit,
    personalDailyLossLimitAction,
    personalDailyProfitTarget,
    personalDailyProfitTargetAction,
    activeTradingAccount.personalDailyLossLimit,
    activeTradingAccount.personalDailyLossLimitAction,
    activeTradingAccount.personalDailyProfitTarget,
    activeTradingAccount.personalDailyProfitTargetAction,
    activeTradingAccount.dailyTradeLimit,
    activeTradingAccount.weeklyTradeLimit,
    maxTradesPerDay,
    maxTradesPerWeek,
    isLoading
  ]);

  const isSettingsChanged = useMemo(() => {
    return risk !== settings.risk || toMake !== settings.toMake || autoApply !== settings.autoApply;
  }, [risk, toMake, autoApply, settings, settings.customSettings, isLoading]);

  const isPersonalLimitChanged = useMemo(() => {
    return (
      personalDailyLossLimit !== activeTradingAccount.personalDailyLossLimit ||
      personalDailyLossLimit != activeTradingAccount.personalDailyLossLimit ||
      personalDailyLossLimitAction != activeTradingAccount.personalDailyLossLimitAction ||
      personalDailyProfitTarget != activeTradingAccount.personalDailyProfitTarget ||
      personalDailyProfitTargetAction != activeTradingAccount.personalDailyProfitTargetAction ||
      maxTradesPerDay != activeTradingAccount.dailyTradeLimit ||
      maxTradesPerWeek != activeTradingAccount.weeklyTradeLimit
    );
  }, [
    personalDailyLossLimit,
    personalDailyLossLimitAction,
    personalDailyProfitTarget,
    personalDailyProfitTargetAction,
    activeTradingAccount.personalDailyLossLimit,
    activeTradingAccount.personalDailyLossLimitAction,
    activeTradingAccount.personalDailyProfitTarget,
    activeTradingAccount.personalDailyProfitTargetAction,
    isLoading
  ]);

  const isTradeLimitChanged = useMemo(() => {
    return maxTradesPerDay != activeTradingAccount.dailyTradeLimit || maxTradesPerWeek != activeTradingAccount.weeklyTradeLimit;
  }, [activeTradingAccount.dailyTradeLimit, activeTradingAccount.weeklyTradeLimit, maxTradesPerDay, maxTradesPerWeek, isLoading]);

  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '0.5em',
        display: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
        overflowY: 'auto',
        maxHeight: '100%',
        backgroundColor: isMobile && '#1c1e23',
        maxWidth: '100%'
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          paddingBottom: '1em',
          paddingTop: 0,
          maxWidth: '100%',
          overflowY: 'auto'
        }}
      >
        <FormGroup sx={{ margin: '1em', width: '100%', maxWidth: '30em' }}>
          <FormLabel>
            Position Risk / Profit Brackets
            <HelpMessage message="These values act like a traditional 'bracket order', and will automatically enter an order to close out the position if the Risk or Profit trigger is hit. Note: risk trigger is exited with a STOP MARKET order." />
          </FormLabel>
          <TextField label='Risk ($)' variant='outlined' value={risk || ''} type='number' InputProps={{ inputProps: { min: 0 } }} style={{ margin: '1em 0' }} onChange={(e) => updateRisk(e.target.value)} />
          <TextField label='Profit ($)' variant='outlined' value={toMake || ''} type='number' InputProps={{ inputProps: { min: 0 } }} style={{ margin: '1em 0' }} onChange={(e) => updateToMake(e.target.value)} />
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={autoApply} onChange={() => setAutoApply(!autoApply)} />} label='Automatically apply Risk / Profit bracket to new Positions' />
          </FormGroup>
        </FormGroup>

        {(activeTradingAccount.type == TradingAccountType.Express || activeTradingAccount.type == TradingAccountType.Sim) && (
          <Box
            sx={{
              position: 'relative',
              margin: '1em',
              minWidth: 'min(80%, 60em)',
              width: 'fit-content',
              maxWidth: '60em',
              gap: '4em',
              padding: '0 1em',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              border: '2px solid #4d4c51',
              borderRadius: '0.5em'
            }}
          >
            <Box sx={{ flex: 1 }}>
              <FormGroup sx={{ margin: '1em', width: '100%', maxWidth: '30em', minWidth: '20em' }}>
                <FormLabel>
                  Personal Daily Loss Limit (PDLL)
                  <HelpMessage message='Set a personal daily loss limit on your account.' />
                </FormLabel>
                <TextField
                  data-intercom-target='pdll-input'
                  variant='outlined'
                  required={personalDailyLossLimitAction != PersonalDailyAction.None}
                  value={personalDailyLossLimit || ''}
                  type='number'
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    inputProps: {
                      min: 0,
                      max: activeTradingAccount.dailyLoss || 100_000
                    }
                  }}
                  onChange={(e) => updateDailyLoss(e.target.value)}
                  disabled={isLockedOut}
                />
                <FormLabel>
                  PDLL Action
                  <HelpMessage message='Sets the action that occurs when your personal daily loss limit is triggered.' />
                </FormLabel>
                <Select disabled={isLockedOut} data-intercom-target='pdll-action' sx={{ width: '18em' }} size='small' onChange={(v) => setPersonalDailyLossLimitAction(v.target.value as any as PersonalDailyAction)} value={personalDailyLossLimitAction}>
                  <MenuItem value={PersonalDailyAction.None} key={PersonalDailyAction.None}>
                    Do Nothing
                  </MenuItem>
                  <MenuItem value={PersonalDailyAction.Liquidate} key={PersonalDailyAction.Liquidate}>
                    Liquidate
                  </MenuItem>
                  <MenuItem value={PersonalDailyAction.LiquidateAndBlock} key={PersonalDailyAction.LiquidateAndBlock}>
                    Liquidate & Block
                  </MenuItem>
                </Select>

                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2em' }}>
                  <FormLabel sx={{ margin: '1em 0 0.5em 0' }}>
                    Personal Daily Profit Target (PDPT)
                    <HelpMessage message='Set a personal daily profit target for your account.' />
                  </FormLabel>
                  <TextField
                    disabled={isLockedOut}
                    variant='outlined'
                    required={personalDailyProfitTargetAction != PersonalDailyAction.None}
                    value={personalDailyProfitTarget || ''}
                    type='number'
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputProps: {
                        min: 0,
                        max: 100000
                      }
                    }}
                    onChange={(e) => updatePersonalDailyProfitTarget(e.target.value)}
                  />
                  <FormLabel sx={{ margin: '1em 0 0.5em 0' }}>
                    PDPT Action
                    <HelpMessage message='Sets the action that occurs when your personal daily profit target is triggered.' />
                  </FormLabel>
                  <Select disabled={isLockedOut} style={{ width: '18em' }} size='small' onChange={(v) => setPersonalDailyProfitTargetAction(v.target.value as any as PersonalDailyAction)} value={personalDailyProfitTargetAction}>
                    <MenuItem value={PersonalDailyAction.None} key={PersonalDailyAction.None}>
                      Do Nothing
                    </MenuItem>
                    <MenuItem value={PersonalDailyAction.Liquidate} key={PersonalDailyAction.Liquidate}>
                      Liquidate
                    </MenuItem>
                    <MenuItem value={PersonalDailyAction.LiquidateAndBlock} key={PersonalDailyAction.LiquidateAndBlock}>
                      Liquidate & Block
                    </MenuItem>
                  </Select>
                </Box>
              </FormGroup>
            </Box>
            <Box sx={{ flex: 1, minWidth: '20em', maxWidth: '30em' }}>
              <FormGroup>
                <FormLabel sx={{ margin: '1em 0 0.5em 0' }}>
                  Trade Limits
                  <HelpMessage
                    message={
                      <>
                        Trade limits include completed round-turn trades. If the trade limit is reached while there are existing working orders, you can manage them beyond the limit, but only liquidating market orders will be accepted once the limit is
                        reached.
                        <br />
                        <br />
                        <strong>WARNING:</strong> Trade Limits on Trade Copier Follower accounts are ignored.
                      </>
                    }
                  />
                </FormLabel>
                <TextField
                  label='Maximum Trades per Day'
                  variant='outlined'
                  value={maxTradesPerDay || ''}
                  type='number'
                  inputProps={{ min: 0 }}
                  onChange={(e) => updateMaxTradesPerDay(e.target.value)}
                  disabled={isLockedOut || activeTradingAccount.currentDayTrades >= (activeTradingAccount.dailyTradeLimit ?? 99999) || activeTradingAccount.currentWeekTrades >= (activeTradingAccount.weeklyTradeLimit ?? 99999)}
                  InputProps={{
                    inputProps: {
                      min: activeTradingAccount.currentDayTrades ?? 0
                    }
                  }}
                />
                <TextField
                  label='Maximum Trades per Week'
                  variant='outlined'
                  value={maxTradesPerWeek || ''}
                  type='number'
                  inputProps={{ min: 0 }}
                  style={{ margin: '1em 0' }}
                  onChange={(e) => updateMaxTradesPerWeek(e.target.value)}
                  disabled={isLockedOut || activeTradingAccount.currentDayTrades >= (activeTradingAccount.dailyTradeLimit ?? 99999) || activeTradingAccount.currentWeekTrades >= (activeTradingAccount.weeklyTradeLimit ?? 99999)}
                  InputProps={{
                    inputProps: {
                      min: activeTradingAccount.currentWeekTrades ?? 0
                    }
                  }}
                />
                {renderTradesRemaining(maxTradesPerDay, currentDayTrades, 'Today')}
                {renderTradesRemaining(maxTradesPerWeek, currentWeekTrades, 'This Week')}
              </FormGroup>
            </Box>
            {isLockedOut ? (
              <>
                {/* Overlay entire section w/ dark */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    zIndex: 999,
                    border: '2px solid rgba(255, 255, 0, 0.5)',
                    backdropFilter: 'blur(0.5px)'
                  }}
                >
                  <Typography variant='h4' sx={{ color: '#fff', textAlign: 'center', marginTop: '5em' }}>
                    Risk Settings Locked Out
                    <HelpMessage
                      style={{ zIndex: 9999 }}
                      message='Your risk settings are currently locked for the remainder of the trading day. You will be able to make adjustments again when the next trading day begins. This helps you stay committed to your original risk plan and maintain discipline.'
                    />
                  </Typography>
                  <div style={{ width: '100%', marginTop: '1em', display: 'flex', justifyContent: 'center' }}>
                    <CountdownTimer targetDate={lockedOutUntil} style={{ color: 'yellow', textAlign: 'center', marginTop: '1em', marginLeft: 'auto', marginRight: 'auto', fontSize: '2em' }} />
                  </div>
                </Box>
              </>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto', marginBottom: '2em', width: '50%' }}>
                <Button buttonType={ButtonType.grey} disabled={isLoading || isChangedAccountSettings} onClick={openModal} sx={{ margin: '1em', width: '100%' }}>
                  LOCK OUT RISK SETTINGS FOR DAY
                </Button>
                {isChangedAccountSettings && <span style={{ display: 'block', color: 'red', marginBottom: '1em', textAlign: 'center' }}>You have unsaved changes.</span>}
              </Box>
            )}
          </Box>
        )}
        <FormGroup sx={{ margin: '1em', minWidth: '15em', width: '100%', maxWidth: '30em' }}>
          <Box>
            <PersonalLockout />
          </Box>
          <Box sx={{ marginTop: '4em' }}>
            <TradeClock />
          </Box>
        </FormGroup>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {(isChanged || showButtons) && (
          <StyledButton color='error' disabled={isLoading} onClick={cancel} sx={{ margin: '1em' }}>
            Cancel
          </StyledButton>
        )}
        {isChanged && (
          <StyledButton color='success' disabled={isLoading} onClick={onSave} sx={{ margin: '1em' }}>
            Save {isLoading && <FontAwesomeIcon spin spinPulse icon={faSpinner} />}
          </StyledButton>
        )}
      </Box>
    </Box>
  );
};

export default RiskSettings;
