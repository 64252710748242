import { useCallback, useEffect, useMemo, useState } from "react";
import { useApi } from "./ApiContext";
import { useTradingAccount } from "./TradingAccountContext";
import { useCqg } from "./CqgContext";
import React from "react";
import Modal, { ModalAction } from "@/components/topstep/modal";
import { useModal } from "./ModalContext";
import { ButtonType } from "@/components/topstep/button";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export interface IRiskSettingsLockoutContext {
  openModal: () => void;
  lockedOutUntil: dayjs.Dayjs | null;
  doSettingsLockout: () => void;
  isLockedOut: boolean;
}

export const RiskSettingsLockoutContext = React.createContext(
  {} as IRiskSettingsLockoutContext
);
export const useRiskSettingsLockout = () =>
  React.useContext<IRiskSettingsLockoutContext>(RiskSettingsLockoutContext);

function RiskSettingsLockoutContextProvider({ children }: any) {
  const { hideModal, showModal } = useModal();
  const { activeTradingAccount } = useTradingAccount();
  const { riskSettingsLockoutApi } = useApi();
  const {} = useCqg();

  const [lockedOutUntil, setLockedOutUntil] = useState<dayjs.Dayjs | null>(null);

  useEffect(() => {
    console.log("Checking risk settings lockout for account", activeTradingAccount);

    if (activeTradingAccount) {
      riskSettingsLockoutApi
        .get(activeTradingAccount.accountId)
        .then((res) => {
          setLockedOutUntil(res?.expiresAt ? res.expiresAt : null);
        });
    } else {
      // If no active trading account, reset
      setLockedOutUntil(null);
    }
  }, [activeTradingAccount?.accountId, riskSettingsLockoutApi]);

  const isLockedOut = useMemo(() => {
    if (!lockedOutUntil) return false;
    return lockedOutUntil.isAfter(dayjs.utc());
  }, [lockedOutUntil]);

  useEffect(() => {
    if (!lockedOutUntil) return;

    const now = dayjs.utc();
    const msUntilExpiry = lockedOutUntil.diff(now);

    // If already expired, clear it out
    if (msUntilExpiry <= 0) {
      setLockedOutUntil(null);
      return;
    }

    // Otherwise, schedule a timeout to clear it
    const timer = setTimeout(() => {
      setLockedOutUntil(null);
    }, msUntilExpiry);

    // Cleanup
    return () => clearTimeout(timer);
  }, [lockedOutUntil]);

  const doSettingsLockout = useCallback(() => {
    if (!activeTradingAccount){ 
        console.warn("Risk settings lockout: No active trading account");
        return;
    }

    // If we are already locked out, skip
    if (isLockedOut) {
        console.warn("Risk settings lockout: Already locked out");
        return;
    }

    console.log("Locking out risk settings");

    riskSettingsLockoutApi
      .addForDay(activeTradingAccount.accountId)
      .then((res) => {
        if (res.success) {
          setLockedOutUntil(res.newExpiresAt);
        }
      });
  }, [activeTradingAccount, isLockedOut, riskSettingsLockoutApi]);

  const actions: ModalAction[] = useMemo(
    () => [
      { label: "Cancel", onClick: hideModal },
      {
        label: "Yes, lock for today",
        props: { buttonType: ButtonType.red },
        onClick: () => {
          doSettingsLockout();
          hideModal();
        },
      },
    ],
    [hideModal, doSettingsLockout]
  );

  const header = useMemo(
    () => (
      <Typography variant="h4" style={{ color: "#fff" }}>
        Lock Risk Settings for Day?
      </Typography>
    ),
    []
  );

  const openModal = useCallback(() => {
    showModal(
      <Modal style={{ maxWidth: "600px" }} actions={actions} header={header}>
        <p style={{ fontSize: "1.5em", lineHeight: "1.5em" }}>
          Locking your risk settings will prevent any adjustments on this account
          for the rest of the trading day. You won't be able to make changes
          until the next trading day begins at 5pm CT.
        </p>
        <p style={{ fontSize: "1.5em" }}>
          The following settings will be deactivated:
        </p>
        <ul style={{ fontSize: "1.5em" }}>
          <li>Personal Daily Loss Limit</li>
          <li>Personal Daily Profit Target</li>
          <li>Trade Limits</li>
        </ul>
        <p style={{ fontSize: "1.5em" }}>
          Are you sure you want to lock your settings for the remainder of the
          day?
        </p>
      </Modal>
    );
  }, [showModal, actions, header]);

  const values = useMemo(() => {
    return {
      openModal,
      lockedOutUntil,
      doSettingsLockout,
      isLockedOut,
    };
  }, [openModal, lockedOutUntil, doSettingsLockout, isLockedOut]);

  return (
    <RiskSettingsLockoutContext.Provider value={values}>
      {children}
    </RiskSettingsLockoutContext.Provider>
  );
}

export default RiskSettingsLockoutContextProvider;
