import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const CST = 'America/Chicago';

function timeOfDayInMilliseconds(hours: number, minutes: number): number {
  return ((hours * 60) + minutes) * 60 * 1000;
}

// Define market times in milliseconds since midnight
const GlobexDayOpen = timeOfDayInMilliseconds(8, 30);    // 8:30 AM
const GlobexOpen = timeOfDayInMilliseconds(15, 15);      // 3:15 PM
const GlobexClose = timeOfDayInMilliseconds(16, 0);      // 4:00 PM
const GlobexNightOpen = timeOfDayInMilliseconds(17, 0);  // 5:00 PM

const MarketClose = timeOfDayInMilliseconds(16, 0);      // 4:00 PM
const MarketOpen = timeOfDayInMilliseconds(17, 0);       // 5:00 PM

export function toCst(val: Dayjs): Dayjs {
  return val.tz(CST);
}

export function getStartOfWeek(val: Dayjs): Dayjs {
  const sessionStart = getTradingDayStart(val);
  const dayOfWeek = sessionStart.day();

  if (dayOfWeek === 6) {
    return sessionStart.add(1, 'day');
  }
  if (dayOfWeek === 0) {
    if (sessionStart.hour() < 16) {
      return sessionStart.subtract(6, 'days');
    }
    return sessionStart;
  }
  return sessionStart.subtract(dayOfWeek, 'days');
}

export function getLastTradeDayStart(originalTime: Dayjs): Dayjs {
  const time = originalTime.tz(CST);
  const dayOfWeek = time.day();
  const hour = time.hour();

  const date = time.startOf('day');

  if (dayOfWeek === 0) {
    if (hour < 17) {
      return date.subtract(3, 'days').add(17, 'hours');
    }
    return date.add(17, 'hours');
  }

  if (dayOfWeek === 6) {
    return date.subtract(2, 'days').add(17, 'hours');
  }

  if (hour >= 17) {
    return date.add(17, 'hours').utc();
  } else {
    return date.subtract(1, 'day').add(17, 'hours').utc();
  }
}

export function toTradeDay(time: Dayjs): Dayjs {
  const marketTime = time.tz(CST);
  const dayOfWeek = marketTime.day();
  const timeOfDayInMilliseconds = marketTime.diff(marketTime.startOf('day'), 'milliseconds');

  if (dayOfWeek === 5 && timeOfDayInMilliseconds >= MarketOpen) {
    return marketTime.add(3, 'days').startOf('day');
  }
  if (dayOfWeek === 6) {
    return marketTime.add(2, 'days').startOf('day');
  }
  if (dayOfWeek === 0) {
    return marketTime.add(1, 'day').startOf('day');
  }
  if (timeOfDayInMilliseconds >= MarketOpen) {
    return marketTime.add(1, 'day').startOf('day');
  }
  return marketTime.startOf('day');
}

export function getTradingDayStart(time: Dayjs): Dayjs {
  const marketTime = time.tz(CST);
  const timeOfDayInMilliseconds = marketTime.diff(marketTime.startOf('day'), 'milliseconds');
  let marketDate = marketTime.startOf('day');

  if (timeOfDayInMilliseconds < GlobexClose) {
    marketDate = marketDate.subtract(1, 'day');
  }

  return marketDate.add(GlobexNightOpen, 'milliseconds');
}

export function getTimeBarIntervalStart(time: Dayjs, intervalInMilliseconds: number): Dayjs {
  const maxInterval = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  if (intervalInMilliseconds > maxInterval) {
    throw new RangeError('Interval cannot exceed 24 hours.');
  }

  const sessionStart = getTradingDayStart(time);
  const elapsedTimeInMilliseconds = time.diff(sessionStart, 'milliseconds');

  const startOffset = Math.floor(elapsedTimeInMilliseconds / intervalInMilliseconds) * intervalInMilliseconds;

  return sessionStart.add(startOffset, 'milliseconds');
}

export function getWeeklySessionStart(time: Dayjs): Dayjs {
  let marketTime = time.tz(CST);

  if (marketTime.day() !== 0) {
    marketTime = marketTime.subtract(marketTime.day(), 'days');
  }

  return marketTime.startOf('day');
}

export function getLastBarTime(time: Dayjs, durationStr: string): Dayjs {
  const ctTime = time.tz(CST); // Set the timezone to CST

  let value: number;
  let unit: string;

  // Handle plain numbers as minutes
  if (/^\d+$/.test(durationStr)) {
    value = parseInt(durationStr, 10);
    unit = 'm'; // Treat as minutes
  } else {
    const match = durationStr.match(/^(\d+)([SHDWMY])$/);
    if (!match) {
      throw new Error('Invalid duration format');
    }
    value = parseInt(match[1], 10);
    unit = match[2];
  }

  let lastBarTime: Dayjs;

  // Map unit to dayjs manipulate type
  let dayjsUnit: ManipulateType;

  switch (unit) {
    case 'S':
      dayjsUnit = 'second';
      break;
    case 'm':
      dayjsUnit = 'minute';
      break;
    case 'H':
      dayjsUnit = 'hour';
      break;
    case 'D':
      dayjsUnit = 'day';
      break;
    case 'W':
      dayjsUnit = 'week';
      break;
    case 'M':
      dayjsUnit = 'month';
      break;
    case 'Y':
      dayjsUnit = 'year';
      break;
    default:
      throw new Error('Unsupported unit.');
  }

  if (['second', 'minute', 'hour'].includes(dayjsUnit)) {
    // For intervals less than a day, use getTimeBarIntervalStart
    let intervalInMilliseconds: number;
    if (dayjsUnit === 'second') {
      intervalInMilliseconds = value * 1000;
    } else if (dayjsUnit === 'minute') {
      intervalInMilliseconds = value * 60 * 1000;
    } else if (dayjsUnit === 'hour') {
      intervalInMilliseconds = value * 60 * 60 * 1000;
    }

    lastBarTime = getTimeBarIntervalStart(ctTime, intervalInMilliseconds);
  } else {
    // For days or larger intervals, base bar times on appropriate start time
    let baseTime: Dayjs;

    if (dayjsUnit === 'day') {
      return toTradeDay(ctTime);
    } else if (dayjsUnit === 'week') {
      return toTradeDay(getStartOfWeek(ctTime));
    } else if (dayjsUnit === 'month') {
      return ctTime.startOf('month');
    } else if (dayjsUnit === 'year') {
      const start = ctTime.startOf('year');
      return start;
    } else {
      throw new Error('Unsupported unit.');
    }

    const diff = ctTime.diff(baseTime, dayjsUnit as any);
    const intervals = Math.floor(diff / value);
    lastBarTime = baseTime.add(intervals * value, dayjsUnit);
  }

  return lastBarTime;
}
