import React, { useCallback, useRef, useEffect, useMemo, useState } from 'react';
import MarketDataOrder from '../../../../models/marketDataOrder';
import { useDragDrop, DragType } from '../../../../contexts/DragDropContext';
import { NotificationManager } from '../../../../components/common/react-notifications';
import { FeOrderResult, FEOrderModel, OrderPromptType } from '../../../../contexts/OrdersContext';
import { OrderModel, OrderType } from '../../../../api/userApi';
import styles from './dom.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCross, faTrash, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { Box, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { faLink } from '@fortawesome/pro-regular-svg-icons';

interface DomOrderProps {
  orders: OrderModel[];
  price: number;
  high: number;
  low: number;
  cancel: (id: number) => Promise<boolean>;
  changeOrderPrice: (id: number, price: number) => Promise<void>;
  leftSide: boolean;
  canTrade: boolean;
  sellBracket?: boolean;
  buyBracket?: boolean;
  isSltp?: boolean;
  isHighlight?: boolean;
  setHighlightId?: (highlightId: string | null) => void;
  orderGroupId?: string;
  positionEntryPrice?: number;
  type: OrderPromptType;
}

const DomOrder: React.FC<DomOrderProps> = ({ orderGroupId, setHighlightId, orders, cancel, price, changeOrderPrice, leftSide, canTrade, buyBracket, sellBracket, isSltp, isHighlight, positionEntryPrice, high, low, type }): JSX.Element => {
  const { dragData, dragType, setDragData } = useDragDrop();
  const [allowDrop, setAllowDrop] = useState(false);

  const isLod = useMemo(() => {
    return price == low;
  }, [price, low]);
  
  const isHod = useMemo(() => {
    return price == high;
  }, [price, high]);

  useEffect(() => {
    if (allowDrop && dragType == DragType.None) {
      setAllowDrop(false);
    }
  }, [dragType, allowDrop]);

  const handleDragStart = useCallback(
    (e: React.DragEvent) => {
      setDragData(orders, orders[0].positionSize > 0 ? DragType.DomBuyOrder : DragType.DomSellOrder);
    },
    [orders]
  );

  const handleDragEnd = useCallback(() => {
    setDragData();
  }, []);

  const handleOnClick = useCallback(async () => {
    for (const o of orders) {
      const res = await cancel(o.id);
    }
  }, [orders]);

  const onDragEnter = useCallback(
    (e: React.DragEvent) => {
      if (dragType == DragType.DomSellOrder || dragType == DragType.DomBuyOrder) {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
        setAllowDrop(true);
      }
    },
    [dragType]
  );

  const onDragLeave = useCallback(
    (e: React.DragEvent) => {
      setAllowDrop(false);
    },
    [dragType]
  );

  const onDragOver = useCallback(
    (e: React.DragEvent) => {
      if (dragType == DragType.DomSellOrder || dragType == DragType.DomBuyOrder) {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
      }
    },
    [price, dragType]
  );

  const onDrop = useCallback(
    async (e: React.DragEvent) => {
      const data = dragData as OrderModel[];
      for (const o of data) {
        const res = await changeOrderPrice(o.id, price);
      }

      setDragData();
    },
    [price, dragData]
  );

  const handleEditOrder = useCallback(async () => {
    // editOrder({
    //   id: order.id,
    //   symbol: order.symbol,
    //   orderType: order.side == 'long' ? 'bid' : 'ask',
    //   price: order.price,
    //   risk: order.risk * -1,
    //   profit: order.profit,
    //   type: order.type,
    //   amount: Math.abs(order.positionSize)
    // });
  }, [orders]);

  const orderType = useMemo(() => {
    const order = orders[0];
    switch (order.type) {
      case OrderType.Limit:
        return 'LMT';
      case OrderType.Stop:
        return 'STPMKT';
      case OrderType.TrailingStop:
        return 'TRLGSTP';
    }
  }, [orders]);

  const orderTypeTooltip = useMemo(() => {
    const order = orders[0];

    switch (order.type) {
      case OrderType.Limit:
        return 'Limit Order';
      case OrderType.Stop:
        return 'Stop Market Order';
      case OrderType.TrailingStop:
        return 'Trailing Stop Market Order';
    }
  }, [orders]);

  const orderSize = useMemo(() => {
    return orders.reduce((a, b) => a + b.positionSize, 0);
  }, [orders]);

  const isBid = useMemo(() => {
    return orderSize > 0;
  }, [orderSize]);

  const handleOnMouseEnter = useCallback(() => {
    if (!!orderGroupId) {
      setHighlightId(orderGroupId);
    }
  }, [orderGroupId]);

  const handleOnMouseLeave = useCallback(() => {
    if (!!orderGroupId) {
      setHighlightId(null);
    }
  }, [orderGroupId]);

  const bidContent = useMemo(() => {
    return (
      <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center', justifyItems: 'center', width: '100%' }} className={styles.orderTypeText}>
        <Box onMouseUp={handleOnClick} sx={{ flex: 0, cursor: 'pointer', backgroundColor: 'rgba(0,0,0,0.4)', padding: '0.2em', alignContent: 'center', justifyContent: 'center' }}>
          <span className={styles.orderTypeSpan}>{orderType}</span>
          <FontAwesomeIcon icon={faXmark} className={styles.bidCancelButton} />
        </Box>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignitems: 'center', cursor: 'move' }} onMouseUp={handleEditOrder}>
          {orderSize} {!!orderGroupId && <FontAwesomeIcon icon={faLink} style={{ fontSize: '0.8em', textAlign: 'center' }} />}
        </Box>
      </Box>
    );
  }, [orderSize, orderGroupId, handleEditOrder]);

  const askContent = useMemo(() => {
    return (
      <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center', justifyItems: 'center' }} className={styles.orderTypeText}>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center', cursor: 'move' }} onMouseUp={handleEditOrder}>
          {orderSize} {!!orderGroupId && <FontAwesomeIcon icon={faLink} style={{ fontSize: '0.8em', textAlign: 'center' }} />}
        </Box>
        <Box onMouseUp={handleOnClick} sx={{ flex: 0, cursor: 'pointer', backgroundColor: 'rgba(0,0,0,0.4)', padding: '0.2em' }}>
          <FontAwesomeIcon icon={faXmark} className={styles.askCancelButton} />
          <span className={styles.orderTypeSpan}>{orderType}</span>
        </Box>
      </Box>
    );
  }, [orderSize, orderGroupId, handleEditOrder]);

  const backgroundColor = useMemo(() => {
    if (isHighlight) {
      return 'blue';
    }

    if (isSltp){
      if (leftSide) {
        if (price <= positionEntryPrice) {
          return '#136713';
        } else {
          return '#cc2525';
        }
      } else {
        if (price >= positionEntryPrice) {
          return '#136713';
        } else {
          return '#cc2525';
        }
      }
    } else {
      return isBid ? '#136713' : '#cc2525';
    }
  }, [isSltp, price, leftSide, positionEntryPrice, isHighlight]);

  return (
    <div
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div
        draggable={canTrade}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        className={classNames(sellBracket && styles.bracketLine, buyBracket && styles.bracketLine)}
        style={{
          backgroundColor: backgroundColor,
          marginRight: !isBid && !isSltp ? '0.6em' : '0',
          marginLeft: isBid && !isSltp ? '0.6em' : '0',
          display: 'flex',
          justifyContent: 'center',
          justifyItems: 'center',
          color: '#fff',
          width: '100%',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: allowDrop ? 'green' : 'transparent'
        }}
      >
        {isBid ? bidContent : askContent}
      </div>
      {isLod && type == OrderPromptType.Sell && <div className={styles.redHeader}>LOD</div>}
      {isHod && type == OrderPromptType.Buy && <div className={styles.greenHeader}>HOD</div>}
    </div>
  );
};

export default DomOrder;
