import { useApi } from '@contexts/ApiContext';
import { Box, Card, CardContent, FormGroup, FormLabel, TextField, Tooltip, Typography } from '@mui/material';
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import Button, { ButtonType } from '@components/topstep/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import HelpMessage from '@components/helpMessage';
import { useTradingAccount } from '@contexts/TradingAccountContext';
import { OrderStatus, SetTradeClockRequest, TradingAccountType } from '@api/userApi';
import { logException } from '@/helpers/exceptionHelper';
import { toast } from 'react-toastify';
import { StyledButton } from '@/components/styledComponents';
import { useDeviceContext } from '@/contexts/DeviceContext';
import dayjs from 'dayjs';
import { useOrders } from '@/contexts/OrdersContext';
import CountdownTimer from '@/components/topstep/countdown';
import { access } from 'fs';
import { useModal } from '@/contexts/ModalContext';
import PersonalLockoutModal from '@/components/topstep/personalLockoutModal';

const PersonalLockout: React.FC = () => {
  const { activeTradingAccount } = useTradingAccount();
  const lockoutExpiration = activeTradingAccount?.lockoutExpiration;
  const { showModal, hideModal } = useModal();

  const lockedOutTemplate = useCallback((message, extraContent = null) => {
    return (
      <Card variant='outlined'>
        <CardContent sx={{ padding: '1.2em' }}>
          <Typography sx={{ fontSize: '1.3em', padding: 0 }}>{message} </Typography>
          {extraContent}
        </CardContent>
      </Card>
    );
  }, []);

  const lockedOutContent = useMemo(() => {
    if (activeTradingAccount.isFollower) {
      return lockedOutTemplate('Personal Lockout disabled. Follower accounts may not utilize this feature.');
    }
    if (activeTradingAccount.lockoutExpiration) {
      return lockedOutTemplate(
        'You are already locked out.',
        <p>
          <CountdownTimer targetDate={lockoutExpiration} onComplete={() => {}} />
          <Typography component='span' sx={{ fontWeight: 500, fontSize: '1.3em' }}>
            {' Remaining'}
          </Typography>
        </p>
      );
    }

    return null;
  }, [activeTradingAccount?.lockoutExpiration, activeTradingAccount?.isFollower]);

  return (
    <FormGroup sx={{ minWidth: '15em', width: '100%', maxWidth: '30em' }}>
      <FormLabel sx={{ margin: '1em 0 0.5em 0' }}>
        Personal Lockout
        <HelpMessage message='Set a personal timed lockout on your account.' />
      </FormLabel>
      {lockedOutContent ? (
        lockedOutContent
      ) : (
        <Button buttonType={ButtonType.grey} onClick={() => showModal(<PersonalLockoutModal account={activeTradingAccount} onCancel={hideModal} onConfirm={hideModal} />)}>
          Set Lockout
        </Button>
      )}
    </FormGroup>
  );
};

export default PersonalLockout;
