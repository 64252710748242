import React, { useMemo } from 'react';
import styles from './balance.module.scss';
import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import { useDeviceContext } from '@/contexts/DeviceContext';
import { useSettings } from '@/contexts/SettingsContext';
import CountdownTimer from './countdown';
import { useTradingAccount } from '@/contexts/TradingAccountContext';
import dayjs from 'dayjs';

type LockdownCountdownProps = {
    padding?: number;
    fontSize?: number;
    variant?: 'default' | 'dark';
    expires: dayjs.Dayjs;
    name: string;
};

const LockoutCountdown = (props: LockdownCountdownProps) => {

    const { isMobile } = useDeviceContext();
    const { customSettings } = useSettings();

    const renderFontSize = useMemo(() => {
        switch (customSettings.topNavTextSize) {
          case 0:
            return '12px';
          case 1:
            return '14px';
          case 2:
            return '16px';
          default:
            return '12px';
        }
      }, [customSettings.topNavTextSize]);

    
    return (
        <div className={classNames(isMobile ? styles.balanceMobile : styles.balance)} style={{ padding: props.padding }}>
            <Tooltip title='Lockout Countdown: The time left until your lockout expires. Note: You may have additional lockouts set.'>
            <div className={styles.balanceAmount} style={{ fontSize: props.fontSize ?? renderFontSize }}>
                <span style={{fontSize: '0.8em'}}><span style={{fontSize: '1.2em'}}>{props.name}: </span><CountdownTimer style={{ color: 'yellow' }} targetDate={props.expires} /></span>
            </div>
            </Tooltip>
        </div>
    );
};

export default LockoutCountdown;