import { useApi } from '../../contexts/ApiContext';
import { FollowTraderEntry, FollowTraderRequest, ITradingAccountModel, TradingAccountStatus } from '../../api/userApi';
import { useTradingAccount } from '../../contexts/TradingAccountContext';
import styles from './copyTrading.module.scss';
import Select from '../topstep/select';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button, { ButtonType } from '../topstep/button';
import Heading, { HeadingSize } from '../topstep/heading';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { StyledButton, StyledTableHeaderCell } from '@components/styledComponents';
import HelpMessage from '@/components/helpMessage';
import { logException } from '@/helpers/exceptionHelper';
import { useDeviceContext } from '@/contexts/DeviceContext';

type Props = {};

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'solid 2px #565656',
  borderRadius: '2px',
  margin: '0',
  background: 'gray'
};

const CopyTrading: React.FC<Props> = ({}): JSX.Element => {
  const { tradingAccountApi } = useApi();
  const { isMobile } = useDeviceContext();
  const { tradingAccounts, refreshTradingAccounts } = useTradingAccount();

  const [followData, setFollowData] = useState<FollowTraderEntry[]>([]);
  const [editingFollowerData, setEditingFollowerData] = useState<number[]>([]);
  const [editingMasterAccount, setEditingMasterAccount] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const isMounted = useRef(true);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedMaster, setSelectedMaster] = useState<ITradingAccountModel | null>(null);
  const [highlightedRows, setHighlightedRows] = useState<number[]>([]); 

  const getAccountName = (account: ITradingAccountModel): string => {
    if (account){
      if (account.nickname) {
        return account.nickname + ' (' + account.accountName + ')';
      } else {
        return account.accountName;
      }
    } else {
      return 'Unknown Account';
    }
  };

  const refresh = useCallback(() => {
    refreshTradingAccounts();
    tradingAccountApi
      .getFollowEntries()
      .then((data) => {
        if (isMounted.current === false) return;
        setFollowData(data);
      })
      .catch((err) => {
        logException(err, 'Failed to get follow entries');
      });
  }, []);

  const addFollower = useCallback((tradingAccountId: number) => {
    setIsEditing(true);
    setEditingFollowerData((prev) => {
      if (prev.includes(tradingAccountId)) return prev;
      else return prev.concat(tradingAccountId);
    });
  }, []);

  const removeFollower = useCallback((tradingAccountId: number) => {
    setIsEditing(true);
    setEditingFollowerData((prev) => prev.filter((x) => x !== tradingAccountId));
  }, []);

  const clearFollowers = useCallback(() => {
    setEditingMasterAccount(0);
    setEditingFollowerData([]);
    setIsEditing(true);
  }, []);

  useEffect(() => {
    const masterId = followData.find((y) => y.leaderAccountId != null);
    if (!masterId) {
      setSelectedMaster(null);
    } else {
      setSelectedMaster(tradingAccounts.find((y) => y.accountId == masterId.leaderAccountId));
    }

    if (isEditing === false) {
      setEditingFollowerData(followData.map((x) => x.tradingAccountId));
      setEditingMasterAccount(followData.find((y) => y.leaderAccountId != null)?.leaderAccountId || null);
    }
  }, [isEditing, followData]);

  const revertChanges = useCallback(() => {
    setEditingFollowerData(followData.map((x) => x.tradingAccountId));
    setEditingMasterAccount(followData.find((y) => y.leaderAccountId != null)?.leaderAccountId || null);
    setIsEditing(false);
  }, [followData]);

  const saveChanges = useCallback(() => {
    //prevent saving if we are already saving
    if (isSaving) return;

    //Set the status to saving so we prevent any other saves
    setIsSaving(true);

    const oldMasters = followData.find((y) => y.leaderAccountId != null);
    const oldMasterId = oldMasters?.leaderAccountId;
    //If we changed the mater account, wipe out all the old followers
    if (editingMasterAccount != oldMasterId) {
      const followNewAccounts = () => {
        //check to make sure user is still on this page
        if (isMounted.current === false) return;

        //add all the new followers back (this will also update the new master)
        Promise.all(editingFollowerData.map((y) => tradingAccountApi.follow(new FollowTraderRequest({ tradingAccountId: y, leaderId: editingMasterAccount, ratio: 1.0 }))))
          .then(() => {
            tradingAccountApi.getFollowEntries().then((data) => {
              if (isMounted.current === false) return;
              setIsSaving(false);
              setIsEditing(false);
              setFollowData(data);
              refresh();
            });
          })
          .catch((err) => {
            logException(err, 'Failed to get follow entries');
            setIsEditing(false);
            setIsSaving(false);
            refresh();
          });
      };
      //erase all old followers

      if (oldMasterId) {
        //clear old followers first, then follow new ones
        tradingAccountApi
          .clearFollowers(oldMasterId)
          .then(followNewAccounts)
          .catch((err) => {
            logException(err, 'Failed to clear old followers');
            setIsEditing(false);
            setIsSaving(false);
            refresh();
          });
      } else {
        //no old master, just follow new accs
        followNewAccounts();
      }
    } else {
      //find the differences in the two sets of followers
      const removedFollowers = followData.filter((x) => !editingFollowerData.includes(x.tradingAccountId));
      const addedFollowers = editingFollowerData.filter((x) => !followData.map((y) => y.tradingAccountId).includes(x));

      //remove all of the old followers
      Promise.all(removedFollowers.map((x) => tradingAccountApi.unfollow(x.tradingAccountId))).then(() => {
        //add all of the new followers
        Promise.all(addedFollowers.map((y) => tradingAccountApi.follow(new FollowTraderRequest({ tradingAccountId: y, leaderId: editingMasterAccount, ratio: 1.0 })))).then(() => {
          tradingAccountApi.getFollowEntries().then((data) => {
            if (isMounted.current === false) return;
            setIsSaving(false);
            setIsEditing(false);
            setFollowData(data);
          });
        });
      });
    }
  }, [editingFollowerData, editingMasterAccount, followData, selectedMaster, isSaving]);

  useEffect(() => {
    refresh();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const accountSelector = useMemo(() => {
    const editingMasterAcc = tradingAccounts.find((x) => x.accountId == editingMasterAccount);
    if (editingMasterAcc) {
      return (
        <div>
          <Heading size={HeadingSize.H2}>
            Lead Account
            <HelpMessage message='Any trades placed in the Lead Account will automatically be copied to the selected follower account(s) and will be traded with the same contract size.' />
          </Heading>
          <div className={styles.accountWrapper}>
            <TextField size='small' label='Selected Leader Account' className={styles.accountSelector} value={getAccountName(editingMasterAcc)} inputProps={{ readOnly: true }}></TextField>
            <StyledButton style={{ flex: 0.5, marginLeft: '2em' }} onClick={clearFollowers}>
              Clear
            </StyledButton>
          </div>
          <Heading size={HeadingSize.H2}>
            Follower Accounts
            <HelpMessage message='Copy Account(s) will mimic any trades or actions from the Lead Account, but all orders are filled individually, so you may see a difference in total profits and losses. You may only select Copy Accounts that have the same Max Position Size of your Lead Account.' />
          </Heading>
        </div>
      );
    }
    return (
      <div>
        <Heading size={HeadingSize.H2}>
          Lead Account
          <HelpMessage message='Any trades placed in the Lead Account will automatically be copied to the selected follower account(s) and will be traded with the same contract size.' />
        </Heading>
        <Select
          onChange={(e) => {
            if (e.target.value) {
              const accountId = parseInt(e.target.value);
              setEditingMasterAccount(accountId);
            }
          }}
        >
          <option>-- None --</option>
          {tradingAccounts
            .filter((y) => y.status == TradingAccountStatus.Active || y.status == TradingAccountStatus.Ineligible)
            .map((x) => (
              <option key={x.accountId} value={x.accountId}>
                {getAccountName(x)}
              </option>
            ))}
        </Select>
      </div>
    );
  }, [editingMasterAccount, tradingAccounts]);

  useEffect(() => {
    setHighlightedRows(editingFollowerData);
  }, [editingFollowerData]);

  const renderAccount = useCallback(
    (account: ITradingAccountModel) => {
      if (editingMasterAccount == null) return null;
      const editingMasterAcc = tradingAccounts.find((x) => x.accountId == editingMasterAccount);
      if (editingMasterAcc == null) return null;
      const useMarginLeader = editingMasterAcc.maxMargin > 0;
      const useMarginChild = account.maxMargin > 0;

      let errorMessage = '';
      let canFollow = true;

      const isHighlighted = highlightedRows.includes(account.accountId); 

      const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
          addFollower(account.accountId);
          setHighlightedRows((prev) => [...prev, account.accountId]); 
        } else {
          removeFollower(account.accountId);
          setHighlightedRows((prev) => prev.filter(id => id !== account.accountId));
        }
      };

      if (useMarginLeader != useMarginChild) {
        errorMessage = 'Both accounts must either use margin, or max position.';
        canFollow = false;
      } else {
        if (useMarginLeader) {
          if (account.maxMargin < editingMasterAcc.maxMargin) {
            errorMessage = `This account has a lower (${account.maxMargin}) max margin than the leader account (${editingMasterAcc.maxMargin}).`;
            canFollow = false;
          }
        } else {
          if (account.maxPositions < editingMasterAcc.maxPositions) {
            errorMessage = `This account has a lower (${account.maxPositions}) max position than your leader (${editingMasterAcc.maxPositions}). Max position must match to follow.`;
            canFollow = false;
          }
        }
      }

      return (
        <TableRow key={account.accountId} style={{ backgroundColor: isHighlighted ? '#404040' : 'transparent' }}>
          <TableCell style={{ padding: '0px 5px', color: '#8e8e8e' }}>{getAccountName(account)}</TableCell>
          <TableCell style={{ padding: '0px 5px' }}>
            {canFollow && (
              <input
                type='checkbox'
                checked={editingFollowerData.includes(account.accountId)}
                onChange={handleCheckboxChange}
                min='1'
                max='100'
              ></input>
            )}
            {!canFollow && <HelpMessage message={errorMessage} />}
          </TableCell>
        </TableRow>
      );
    },
    [tradingAccounts, editingMasterAccount, editingFollowerData]
  );

  const saveTradeCopierChanges = useMemo(() => {
    if (isEditing) {
      return (
        <div style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center', paddingTop: '1em' }}>
          <StyledButton color='error' sx={{ flex: 0.5, marginLeft: '2em', width: '20em', marginTop: 1 }} disabled={isSaving} onClick={revertChanges}>
            Cancel
          </StyledButton>
          <StyledButton color='success' sx={{ flex: 0.5, marginLeft: '2em', width: '20em', marginTop: 1 }} disabled={isSaving} onClick={saveChanges}>
            Save Changes
          </StyledButton>
        </div>
      );
    }
  }, [isEditing, isSaving, saveChanges, revertChanges]);

  const validAccounts = useMemo(
    () => tradingAccounts.filter((x) => (x.status == TradingAccountStatus.Active || x.status == TradingAccountStatus.Ineligible) && x.accountId != selectedMaster?.accountId),
    [tradingAccounts, selectedMaster]
  );

  return useMemo(() => {
    const editingMasterAcc = tradingAccounts.find((x) => x.accountId == editingMasterAccount);

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'center',
          overflowY: 'hidden',
          maxHeight: '100%',
          margin: '1em',
          backgroundColor: isMobile && '#1c1e23'
        }}
      >
        {accountSelector}
        {!!editingMasterAccount && !!editingMasterAcc && (
          <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: '100%', overflow: 'hidden' }}>
            <div className={styles.warningBoxContainer}>
              <div className={styles.warningBox}>
                ⚠️ Warning: copy trading copies <strong>ORDERS</strong>, not executions. ⚠️
                <br /> This may result in different fills and P&L between the lead and follower accounts during periods of volatility.
              </div>
            </div>
            <Box sx={{ flex: 1, overflowY: 'auto', maxHeight: '100%' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableHeaderCell>
                      <Typography style={{ fontWeight: 'bold' }}> Account</Typography>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell>
                      <Typography style={{ fontWeight: 'bold' }}> Follow</Typography>
                    </StyledTableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {validAccounts
                    .filter((x) => (x.status == TradingAccountStatus.Active || x.status == TradingAccountStatus.Ineligible) && editingMasterAccount != x.accountId)
                    .map((x) => renderAccount(x))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        )}
        {saveTradeCopierChanges}
      </Box>
    );
  }, [followData, validAccounts, accountSelector, saveTradeCopierChanges, selectedMaster, editingMasterAccount, renderAccount]);
};

export default CopyTrading;
