import { useApi } from '@contexts/ApiContext';
import { Box, Card, CardContent, FormGroup, FormLabel, TextField, Tooltip, Typography } from '@mui/material';
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import Button, { ButtonType } from '@components/topstep/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import HelpMessage from '@components/helpMessage';
import { useTradingAccount } from '@contexts/TradingAccountContext';
import { OrderStatus, SetTradeClockRequest, TradingAccountType } from '@api/userApi';
import { logException } from '@/helpers/exceptionHelper';
import { toast } from 'react-toastify';
import { StyledButton } from '@/components/styledComponents';
import { useDeviceContext } from '@/contexts/DeviceContext';
import dayjs from 'dayjs';
import { useOrders } from '@/contexts/OrdersContext';
import CountdownTimer from '@/components/topstep/countdown';
import { access } from 'fs';

interface TradeClockProps {
  onSaved?: () => void;
  onCancelled?: () => void;
}

const TradeClock: React.FC<TradeClockProps> = ({ onSaved, onCancelled }) => {
  const { tradeClockApi } = useApi();
  const { activeTradingAccount } = useTradingAccount();
  const { isMobile } = useDeviceContext();
  const [duration, setDuration] = useState<number | null>(undefined);
  const [customDuration, setCustomDuration] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { activePositions, orders } = useOrders();
  const hasOpenPositions = useMemo(() => activePositions?.length > 0, [activePositions]);
  const hasPendingOrders = useMemo(() => orders?.filter((y) => y.status == OrderStatus.Open).length > 0, [orders]);
  const isFollower = activeTradingAccount && activeTradingAccount.isFollower;

  useEffect(() => {
    setDuration(undefined);
    setCustomDuration(null);
  }, [activeTradingAccount]);

  const handleDurationChange = (value: number) => {
    setDuration(value);
    setCustomDuration(null);
  };

  const handleCustomDurationChange = (value: string) => {
    const parsedValue = parseInt(value);
    if (isNaN(parsedValue) || parsedValue <= 0) {
      setCustomDuration(null);
    } else {
      setCustomDuration(parsedValue);
      setDuration(0);
    }
  };

  const handleEnableTradeClock = () => {
    setIsLoading(true);
    const selectedDuration = duration || customDuration;
    if (!selectedDuration) {
      toast('Please select a duration', { type: 'error' });
      setIsLoading(false);
      return;
    }

    tradeClockApi
      .setTradeClock(new SetTradeClockRequest({ duration: selectedDuration * 60, tradingAccountId: activeTradingAccount.accountId }))
      .then((res) => {
        setIsLoading(false);
        if (!res.success) {
          toast('Unable to set Trade Clock. ' + res.message, { type: 'error' });
          onSaved && onSaved();
        } else {
          toast('Trade Clock enabled', { type: 'success' });
          onSaved && onSaved();
          setDuration(undefined);
          setCustomDuration(null);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        logException(e, 'Error enabling Trade Clock');
        toast('Error enabling Trade Clock: ', { type: 'error' });
      });
  };

  const handleCancel = () => {
    setDuration(undefined);
    setCustomDuration(null);
    onCancelled && onCancelled();
  };

  const isButtonEnabled = useMemo(() => {
    return duration !== null || customDuration !== null;
  }, [duration, customDuration]);

  const helpMessage = useMemo(() => {
    return (
      <Box>
        <p>Enabling the Trade Clock restricts entering new orders and adjustments to existing orders/positions. Only Flatten All and Cancel will be available until the restriction expires.</p>
      </Box>
    );
  }, []);

  const hasLockoutExpiration = useMemo(() => {
    return !!activeTradingAccount?.lockoutExpiration;
  }, [activeTradingAccount.lockoutExpiration]);

  useEffect(() => {
    setDuration(undefined);
    setCustomDuration(null);
  }, [activeTradingAccount]);

  const lockedOutTemplate = useCallback((message, extraContent = null) => {
    return (
      <Card variant='outlined'>
        <CardContent sx={{ padding: '1.2em' }}>
          <Typography sx={{ fontSize: '1.3em', padding: 0 }}>Trade Clock disabled. {message} </Typography>
          {extraContent}
        </CardContent>
      </Card>
    );
  }, []);

  const lockedOutContent = useMemo(() => {
    if (activeTradingAccount?.type == TradingAccountType.Live) {
      return lockedOutTemplate('This feature is not yet currently available for live accounts.');
    }
    if (activeTradingAccount?.isFollower) {
      return lockedOutTemplate('Follower accounts may not utilize this feature.');
    }
    if (activeTradingAccount?.lockoutExpiration) {
      return lockedOutTemplate('You are already locked out.');
    }
    if (!hasOpenPositions && !hasPendingOrders) {
      return lockedOutTemplate('You need an active position and/or orders to utilize this feature.');
    }
    return null;
  }, [activeTradingAccount?.lockoutExpiration, hasOpenPositions, hasPendingOrders, activeTradingAccount?.isFollower, activeTradingAccount?.type]);

  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '0.5em',
        display: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
        overflowY: 'auto',
        maxHeight: '100%',
        backgroundColor: isMobile && '#1c1e23',
        maxWidth: '100%'
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          paddingBottom: '1em',
          paddingTop: 0,
          maxWidth: '100%',
          overflowY: 'auto'
        }}
      >
        <FormGroup sx={{ width: '100%', maxWidth: '30em' }}>
          <FormLabel sx={{ margin: '0 0 0.5em 0' }}>
            Trade Clock
            <HelpMessage message={helpMessage} />
          </FormLabel>
          {lockedOutContent}
          {!lockedOutContent && (
            <>
              {(duration == 0 || duration) && (
                <>
                  <Typography color='red' sx={{ marginTop: '1em' }}>
                    Warning: This action is irreversible and only Flatten All & Cancel All will be available.
                  </Typography>
                  {(duration != 0 || customDuration) && (
                    <Typography color='grey'>
                      This will only affect the account {activeTradingAccount.nickname ?? activeTradingAccount.accountName} for {duration == 0 ? customDuration : duration} minute(s). During this time you will not be able to place any new orders, only modify
                      existing positions and orders.
                    </Typography>
                  )}
                </>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1em', marginTop: '1em' }}>
                <Button selected={duration == 1} buttonType={ButtonType.grey} onClick={() => handleDurationChange(1)} disabled={hasLockoutExpiration}>
                  1 min
                </Button>
                <Button selected={duration == 2} buttonType={ButtonType.grey} onClick={() => handleDurationChange(2)} disabled={hasLockoutExpiration}>
                  2 min
                </Button>
                <Button selected={duration == 5} buttonType={ButtonType.grey} onClick={() => handleDurationChange(5)} disabled={hasLockoutExpiration}>
                  5 min
                </Button>
                <Button selected={duration == 10} buttonType={ButtonType.grey} onClick={() => handleDurationChange(10)} disabled={hasLockoutExpiration}>
                  10 min
                </Button>
                <Button selected={duration == 0} buttonType={ButtonType.grey} onClick={() => handleDurationChange(0)} disabled={hasLockoutExpiration}>
                  Custom
                </Button>
              </Box>
              {duration === 0 && (
                <TextField
                  label='Custom Duration (minutes)'
                  variant='outlined'
                  value={customDuration || ''}
                  type='number'
                  InputProps={{ inputProps: { min: 1, max: 1440 } }}
                  style={{ margin: '1em 0' }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 4) {
                      handleCustomDurationChange(value);
                    }
                  }}
                  disabled={hasLockoutExpiration}
                />
              )}

              {(duration == 0 || duration) && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <StyledButton color='error' disabled={isLoading} onClick={handleCancel} sx={{ margin: '1em' }}>
                    Cancel
                  </StyledButton>

                  <Box>
                    <StyledButton color={isButtonEnabled ? 'success' : 'primary'} disabled={isLoading || !isButtonEnabled} onClick={handleEnableTradeClock} sx={{ margin: '1em' }}>
                      Enable Trade Clock {isLoading && <FontAwesomeIcon spin spinPulse icon={faSpinner} />}
                    </StyledButton>
                  </Box>
                </Box>
              )}
            </>
          )}
        </FormGroup>
      </Box>
    </Box>
  );
};

export default TradeClock;
